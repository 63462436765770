import React from "react"
import Jumbotron from "src/components/jumbotron"
import Layout from "src/components/layout"
import JustLogoHeader from "src/components/marketing/just-logo"
import "src/styles/pages/landing-page.scss"

export default function TutorialVideo() {
  return (
    <Layout className="landing-page-root">
      <JustLogoHeader />
      <Jumbotron
        className="marketing-jumbotron"
        section={{
          image:
            "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png",
        }}
      >
        <p>
          In diesem Video stellt dir Lukas Stenzel unsere Mindance App vor.
          Lukas hat einen Master of Science in Psychologie, absolvierte das HR
          Trainee Programm bei Bosch und ist ausgebildeter Achtsamkeitstrainer
          und Sportpsychologe. Er promoviert berufsbegleitend an der
          Martin-Luther-Universität Halle-Wittenberg und untersucht dabei
          app-basierte psychologische Interventionen in Organisationen und im
          Sport. Mit seinem psychologischen Fachwissen, seiner HR Erfahrung und
          Leidenschaft für Psychologie ist er verantwortlich für die Inhalte von
          Mindance und die wissenschaftliche Evaluierung.
        </p>
        <iframe
          src={"https://d3j42soofhp7qt.cloudfront.net/video/Mindance.mp4"}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </Jumbotron>
    </Layout>
  )
}
